import React from 'react';
import classnames from 'classnames';
import { batch, useDispatch } from 'react-redux';
import toggleChatPopup from '../../../utils/toggleChatPopup';
import { setBoardInCropper } from '../../../redux/boards/actions';
import { updateApp } from '../../../redux/app/actions';
import { ReactComponent as EditIcon } from '../../../assets/images/add-image/33-edit_photo_icon-wht.svg';
import { ReactComponent as RemoveIcon } from '../../../assets/images/add-image/34-remove-close_icon-wht.svg';
import './BoardMenu.scss';
import { isMobile } from '../../../utils/dimensions';

const CLASS = 'sb-BoardMenu';

const BoardMenu = ({ show, board, setShow, onRemove, isOverBoard }) => {
  const dispatch = useDispatch();

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove();
    setShow(false);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    batch(() => {
      dispatch(setBoardInCropper(board));
      dispatch(updateApp({ modals: { cropper: true } }));
    });
    setShow(false);
  };

  const boardMenuClass = classnames(CLASS, {
    [`${CLASS}-dock`]: !isOverBoard,
    [`${CLASS}-overBoard`]: isOverBoard,
    [`${CLASS}-show`]: show,
  });

  return (
    <>
      {isMobile ? (
        <div
          className={boardMenuClass}
          style={{
            gap: '24px',
            display: 'flex',
            background: 'white',
            flexDirection: 'column',
            padding: '24px 16px 24px 16px',
            borderRadius: '20px 20px 0px 0px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                fontSize: '18ox',
                fontWeight: 700,
                color: 'rgba(0,0,0,.8)',
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            >
              Customize photo
            </span>
            <div
              style={{
                fontWeight: 700,
                color: '#A638FC',
                fontSize: '16px',
                cursor: 'pointer',
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
              onClick={() => {
                setShow(false);
              }}
            >
              Done
            </div>
          </div>
          <button
            onClick={handleEdit}
            style={{
              background: '#fff',
              borderRadius: '12px',
              border: '1.5px solid #AD37FD',
              padding: '10px 16px 10px 16px',
              boxShadow:
                '0px -3px 2px 0px #A637FC33 inset, 0px 1px 4px 0px #00000026',
            }}
          >
            <div
              style={{
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_2536_382"
                  style={{ maskType: 'alpha' }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2536_382)">
                  <path
                    d="M17.25 22.75V18.75H7.05775C6.55258 18.75 6.125 18.575 5.775 18.225C5.425 17.875 5.25 17.4474 5.25 16.9423V6.75H1.25V5.25H5.25V1.25H6.75V16.9423C6.75 17.0192 6.78208 17.0898 6.84625 17.1538C6.91025 17.2179 6.98075 17.25 7.05775 17.25H22.75V18.75H18.75V22.75H17.25ZM17.25 15.75V7.05775C17.25 6.98075 17.2179 6.91025 17.1538 6.84625C17.0898 6.78208 17.0192 6.75 16.9423 6.75H8.25V5.25H16.9423C17.4474 5.25 17.875 5.425 18.225 5.775C18.575 6.125 18.75 6.55258 18.75 7.05775V15.75H17.25Z"
                    fill="#AD37FD"
                  />
                </g>
              </svg>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#AD37FD',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                }}
              >
                Adjust Cropping
              </span>
            </div>
          </button>
          <button
            onClick={handleRemove}
            style={{
              background: '#fff',
              borderRadius: '12px',
              padding: '10px 16px 10px 16px',
              border: '1.5px solid rgb(221, 7, 196)',
              boxShadow:
                '0px -3px 2px 0px #A637FC33 inset, 0px 1px 4px 0px #00000026',
            }}
          >
            <div
              style={{
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_2536_377"
                  maskUnits="userSpaceOnUse"
                  style={{ maskType: 'alpha' }}
                  x="0"
                  y="0"
                  width="25"
                  height="24"
                >
                  <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2536_377)">
                  <path
                    d="M7.80775 20.5C7.30908 20.5 6.88308 20.3234 6.52975 19.9703C6.17658 19.6169 6 19.1909 6 18.6923V6.00001H5V4.50001H9.5V3.61551H15.5V4.50001H20V6.00001H19V18.6923C19 19.1974 18.825 19.625 18.475 19.975C18.125 20.325 17.6974 20.5 17.1923 20.5H7.80775ZM17.5 6.00001H7.5V18.6923C7.5 18.7821 7.52883 18.8558 7.5865 18.9135C7.64417 18.9712 7.71792 19 7.80775 19H17.1923C17.2692 19 17.3398 18.9679 17.4038 18.9038C17.4679 18.8398 17.5 18.7693 17.5 18.6923V6.00001ZM9.904 17H11.4037V8.00001H9.904V17ZM13.5962 17H15.096V8.00001H13.5962V17Z"
                    fill="#DD07C4"
                  />
                </g>
              </svg>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#DD07C4',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                }}
              >
                Remove
              </span>
            </div>
          </button>
        </div>
      ) : (
        <div className={boardMenuClass}>
          <div>
            <button
              onClick={handleEdit}
              style={{
                display: 'flex',
                background: '#fff',
                borderRadius: '12px',
                justifyContent: 'center',
                border: '1.5px solid #AD37FD',
                padding: '10px 16px 10px 16px',
                boxShadow:
                  '0px -3px 2px 0px #A637FC33 inset, 0px 1px 4px 0px #00000026',
              }}
            >
              <div
                style={{
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_2536_382"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2536_382)">
                    <path
                      d="M17.25 22.75V18.75H7.05775C6.55258 18.75 6.125 18.575 5.775 18.225C5.425 17.875 5.25 17.4474 5.25 16.9423V6.75H1.25V5.25H5.25V1.25H6.75V16.9423C6.75 17.0192 6.78208 17.0898 6.84625 17.1538C6.91025 17.2179 6.98075 17.25 7.05775 17.25H22.75V18.75H18.75V22.75H17.25ZM17.25 15.75V7.05775C17.25 6.98075 17.2179 6.91025 17.1538 6.84625C17.0898 6.78208 17.0192 6.75 16.9423 6.75H8.25V5.25H16.9423C17.4474 5.25 17.875 5.425 18.225 5.775C18.575 6.125 18.75 6.55258 18.75 7.05775V15.75H17.25Z"
                      fill="#AD37FD"
                    />
                  </g>
                </svg>
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#AD37FD',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                  }}
                >
                  Adjust Cropping
                </span>
              </div>
            </button>
            <button
              onClick={handleRemove}
              style={{
                display: 'flex',
                background: '#fff',
                borderRadius: '12px',
                justifyContent: 'center',
                padding: '10px 16px 10px 16px',
                border: '1.5px solid rgb(221, 7, 196)',
                boxShadow:
                  '0px -3px 2px 0px #A637FC33 inset, 0px 1px 4px 0px #00000026',
              }}
            >
              <div
                style={{
                  gap: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_2536_377"
                    maskUnits="userSpaceOnUse"
                    style={{ maskType: 'alpha' }}
                    x="0"
                    y="0"
                    width="25"
                    height="24"
                  >
                    <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2536_377)">
                    <path
                      d="M7.80775 20.5C7.30908 20.5 6.88308 20.3234 6.52975 19.9703C6.17658 19.6169 6 19.1909 6 18.6923V6.00001H5V4.50001H9.5V3.61551H15.5V4.50001H20V6.00001H19V18.6923C19 19.1974 18.825 19.625 18.475 19.975C18.125 20.325 17.6974 20.5 17.1923 20.5H7.80775ZM17.5 6.00001H7.5V18.6923C7.5 18.7821 7.52883 18.8558 7.5865 18.9135C7.64417 18.9712 7.71792 19 7.80775 19H17.1923C17.2692 19 17.3398 18.9679 17.4038 18.9038C17.4679 18.8398 17.5 18.7693 17.5 18.6923V6.00001ZM9.904 17H11.4037V8.00001H9.904V17ZM13.5962 17H15.096V8.00001H13.5962V17Z"
                      fill="#DD07C4"
                    />
                  </g>
                </svg>
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#DD07C4',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                  }}
                >
                  Remove
                </span>
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BoardMenu;
