export const AddImageButtonIconMobile = (props) => (
  <svg
    width="82"
    height="94"
    viewBox="0 0 82 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ddddii_2436_2444)">
      <rect
        x="7"
        y="1.5"
        width="68"
        height="68"
        rx="34"
        fill="url(#paint0_linear_2436_2444)"
      />
      <g filter="url(#filter1_i_2436_2444)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41 17.5C39.3431 17.5 38 18.8431 38 20.5V32.5H26C24.3431 32.5 23 33.8431 23 35.5C23 37.1569 24.3431 38.5 26 38.5H38V50.5C38 52.1569 39.3431 53.5 41 53.5C42.6569 53.5 44 52.1569 44 50.5V38.5H56C57.6569 38.5 59 37.1569 59 35.5C59 33.8431 57.6569 32.5 56 32.5H44V20.5C44 18.8431 42.6569 17.5 41 17.5Z"
          fill="#AD37FD"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ddddii_2436_2444"
        x="0"
        y="0.5"
        width="82"
        height="93"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2436_2444"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2436_2444"
          result="effect2_dropShadow_2436_2444"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_2436_2444"
          result="effect3_dropShadow_2436_2444"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="17" />
        <feGaussianBlur stdDeviation="3.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_2436_2444"
          result="effect4_dropShadow_2436_2444"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect4_dropShadow_2436_2444"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect5_innerShadow_2436_2444"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_innerShadow_2436_2444"
          result="effect6_innerShadow_2436_2444"
        />
      </filter>
      <filter
        id="filter1_i_2436_2444"
        x="23"
        y="17.5"
        width="36"
        height="37"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2436_2444"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2436_2444"
        x1="41"
        y1="1.5"
        x2="41"
        y2="69.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="1" stop-color="#F5F5F5" />
      </linearGradient>
    </defs>
  </svg>
);
