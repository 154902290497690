import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import BoardFrame from './BoardFrame';
import BoardImage from './BoardImage';
import UploadImage from '../upload-image';
import BoardMenu from '../../../views/components/board-menu';
import toggleChatPopup from '../../../utils/toggleChatPopup';
import { usePrevious } from '../../../utils/hooks';
import {
  setBoardInCropper,
  updateWidthAndHeight,
} from '../../../redux/boards/actions';

import './Board.scss';

const CLASS = 'sb-Board';

const Board = ({
  board,
  upload,
  setBoardInMenu,
  onUpload,
  isLeft,
  onRemove,
  index,
  nema,
}) => {
  const [boardMenu, setBoardMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [image, setImage] = useState(null);

  const dispatch = useDispatch();
  const { boards } = useSelector((state) => state);
  const { coupon } = useSelector((state) => state.coupon);

  const boardRef = useRef(null);
  const previousCoupon = usePrevious(coupon);

  const showMenu = boardMenu && !upload;
  const boardContainerClass = classnames(CLASS, {
    [`${CLASS}-upload-left`]: isLeft,
    appear: !upload,
    // margin: index === 0,
  });

  useEffect(() => {
    boardRef.current.addEventListener(
      'animationend',
      handleAnimationEnd,
      false,
    );
    if (board?.secureUrl) {
      setImage(board.secureUrl);
    } else {
      setImage(board?.blob);
    }

    handleSetDiscount();

    return () =>
      boardRef.current.removeEventListener(
        'animationend',
        handleAnimationEnd,
        false,
      );
  }, []);

  useEffect(() => {
    if (coupon && (!previousCoupon || previousCoupon._id !== coupon._id)) {
      handleSetDiscount();
    }
  }, [coupon]);

  const handleSetDiscount = () => {
    let newDiscount =
      coupon &&
      coupon.discountPercentage &&
      `${coupon.discountPercentage.toFixed(0)}%`;
    if (!newDiscount) {
      newDiscount =
        coupon &&
        coupon.discountAmount &&
        `$${coupon.discountAmount.toFixed(0)}`;
    }

    if (coupon && coupon.name.startsWith('GIFT')) {
      newDiscount = null;
    }

    setDiscount(newDiscount);
  };

  const handleImageLoaded = (event) => {
    if (event && !upload) {
      const imgElement = event.target;
      dispatch(updateWidthAndHeight(board, imgElement));
    }

    setIsLoaded(true);
  };

  const handleAnimationEnd = (event) => {
    const { animationName, target } = event;
    if (animationName === 'board-slide-right') {
      target.classList.remove('right');
      target.classList.remove('right-initial');
    }
    if (animationName === 'board-slide-left') {
      target.classList.remove('left');
      target.classList.remove('left-initial');
    }
    target.classList.add('no-animation');
  };

  const renderUpload = () => {
    if (!upload) return null;
    return (
      <div className={`${CLASS}-container ${CLASS}-container-upload`}>
        <UploadImage
          discount={discount}
          left={!!isLeft}
          onUpload={(blob) => onUpload(blob, boardRef.current)}
        />
      </div>
    );
  };

  const handleSetBoardMenu = (show) => {
    setBoardMenu(show);
    toggleChatPopup(!show, 'setBoardMenu');
  };

  const handleMouseOver = () => {
    if (document.documentElement?.clientWidth < 990) return;
    !upload && handleSetBoardMenu(true);
    setBoardInMenu(board?.id, boardRef.current);
  };

  const handleMouseLeave = () => {
    if (document.documentElement?.clientWidth < 990) return;
    handleSetBoardMenu(false);
  };

  const handleClick = (e) => {
    if (upload || document.documentElement?.clientWidth >= 990) return;

    setBoardInMenu(board?.id, boardRef.current);
    handleSetBoardMenu(boardMenu);
    dispatch(setBoardInCropper(board));
  };

  return (
    <div
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseOver}
      className={boardContainerClass}
      ref={boardRef}
    >
      <CSSTransition
        unmountOnExit
        in={showMenu}
        classNames={'sb-BoardMenu-animation'}
        timeout={300}
      >
        <BoardMenu
          isOverBoard
          show={showMenu}
          onRemove={onRemove}
          key={1}
          setShow={handleSetBoardMenu}
          board={board}
        />
      </CSSTransition>
      {!!nema && (
        <BoardImage
          upload={upload}
          board={board}
          isLoaded={isLoaded}
          image={image}
          handleImageLoaded={handleImageLoaded}
        />
      )}
      {renderUpload()}
      <div className="scroll-fix"></div>
    </div>
  );
};

export default Board;
