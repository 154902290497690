import React from 'react';
import { a_materialSelected } from '../../../api/analytics/index';

const SingleFrame = ({
  defaultSrc,
  name,
  text,
  text2,
  text3,
  isShown,
  isSelected,
  handleSelectFrame,
}) => {
  return (
    <div
      onClick={() => {
        handleSelectFrame(name);
        a_materialSelected(name);
      }}
      className={`single-frame ${!isShown && 'hide'} ${
        isSelected && 'selected'
      }`}
    >
      <img
        src={defaultSrc}
        alt="Storyboards frame"
        style={{
          width: '100%',
          height: '97px',
          objectFit: 'cover',
          borderRadius: '0px 0px 16px 16px',
        }}
      />
      <div
        style={{
          display: 'flex',
          padding: '8px 12px',
          flexDirection: 'column',
          fontFamily: "'Plus Jakarta Sans', sans-serif",
        }}
      >
        <p
          style={{
            fontFamily: 'inherit',
            fontSize: '16px',
            fontWeight: 700,
            color: isSelected ? '#AD37FD' : 'rgba(0,0,0,.8)',
          }}
        >
          {text}
        </p>
        <span
          style={{
            fontFamily: 'inherit',
            fontSize: '12px',
            fontWeight: 600,
            color: isSelected ? '#AD37FD' : 'rgba(0,0,0,.5)',
          }}
        >
          {text2}
        </span>
        <span
          style={{ fontFamily: 'inherit', fontSize: '12px', fontWeight: 500 }}
        >
          {text3}
        </span>
      </div>
    </div>
  );
};

export default SingleFrame;
