export const SizeIcon = (props) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_ii_2467_4549)">
      <rect x="7.5" y="7" width="14" height="14" rx="3" fill="#F1F1F1" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 0H4.5C2.29086 0 0.5 1.79086 0.5 4V7H3.5V5C3.5 3.89543 4.39543 3 5.5 3H7.5V0ZM21.5 3V0H24.5C26.7091 0 28.5 1.79086 28.5 4V7H25.5V5C25.5 3.89543 24.6046 3 23.5 3H21.5ZM21.5 25H23.5C24.6046 25 25.5 24.1046 25.5 23V21H28.5V24C28.5 26.2091 26.7091 28 24.5 28H21.5V25ZM3.5 21V23C3.5 24.1046 4.39543 25 5.5 25H7.5V28H4.5C2.29086 28 0.5 26.2091 0.5 24V21H3.5Z"
        fill="#A2E6ED"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_2467_4549"
        x="0.5"
        y="-0.5"
        width="28"
        height="29.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2467_4549"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_2467_4549"
          result="effect2_innerShadow_2467_4549"
        />
      </filter>
    </defs>
  </svg>
);
