import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Board from '../../components/board';
import { getNextSiblings } from '../../../utils/dom';
import { clickAndDrag, drag } from '../../../utils/clickAndDrag';
import classNames from 'classnames';
import { boardsActions } from '../../../redux/boards';
import * as events from '../../../utils/events';

import './BoardsStripNew.scss';

import starStruck from '../../../assets/images/star-struck.png';
import { nanoid } from 'nanoid';
import { isMobile } from '../../../utils/dimensions';
import { usePrevious } from '../../../utils/hooks';
import { CSSTransition } from 'react-transition-group';

import { Tag } from '../../../assets/images/Tag';
import { AddImageButtonIcon } from '../../../assets/images/AddImageButtonIcon';
import { AddImageButtonIconMobile } from '../../../assets/images/AddImageButtonIconMobile';

const CLASS = 'sb-BoardsStrip';

const BoardsStrip = ({
  setBoardInMenu,
  onRemove,
  toggleCropper,
  showBoardMenu,
}) => {
  const [initialUpload, setInitialUpload] = useState();
  const dispatch = useDispatch();
  const { app, checkout } = useSelector((state) => state);
  const { boards } = useSelector((state) => state.boards);
  const { coupon } = useSelector((state) => state.coupon);
  const { discount } = useSelector((state) => state.checkout);
  const framesData = app.cms.frames;
  const frame = useSelector((state) => state.boards.selectedFrame);

  const newBoards = boards;
  let upload = null;
  const { uploadImage, hideUploadOptions } = boardsActions;

  const boardRefFirst = useRef();
  let activeImages = framesData?.filter((x) => x.isShown).length;

  const previousFrame = usePrevious(frame);

  const { topTierDiscount, boardsUpsellMessage } = checkout;

  let btext = coupon?.btext;
  if (btext === 'HIDE' || btext === 999 || btext === '999') {
    btext = null;
  }

  if (discount && discount.couponType && discount.couponType.BUND) {
    btext = discount.ctext;
  }

  let highestDiscount;

  const messages = [
    'Picture perfect!',
    'Memories in the making!',
    'Level Up!',
    "Keep 'em coming!",
    'Awesome collection!',
    "You're on a roll!",
    "Can't stop, won't stop!",
  ];

  const [currentMessage, setCurrentMessage] = useState(messages[0]);

  const getRandomMessage = () => {
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  };

  const rotateMessage = () => {
    setCurrentMessage(getRandomMessage());
  };

  try {
    highestDiscount = Object.values(coupon?.override)
      .map(({ tiers }) => tiers)
      .flat()
      .sort((a, b) => a.discount - b.discount)
      .reverse()[0].discount;
  } catch {}

  const loadFiles = (files) => {
    const images = [];
    for (let file of files) {
      const id = nanoid();
      const blob = URL.createObjectURL(file);
      images.push({ id, file, blob, frame });
    }
    onUpload(images);
    dispatch(uploadImage(images, false, app.remarketingId));
    if (upload) upload.value = null;
    rotateMessage();
  };

  const onFileChange = (event) => {
    const { files } = event.target;
    loadFiles(files);
  };

  const onUploadClick = (e) => {
    e.stopPropagation();
    events.onUploadClick();
    upload.click();
    dispatch(hideUploadOptions());
  };

  const BS_CLASS = classNames(CLASS, {
    [`${CLASS}-noBoards`]: boards === null || boards?.length < 1,
    [`${CLASS}-iOS`]: app.platform.os === 'iOS',
    [`${CLASS}-frames`]: activeImages >= 2,
    [`${CLASS}-offer`]: !!btext,
  });

  useEffect(() => {
    clickAndDrag(CLASS);
    drag('.drag');
  }, []);

  const onUploadLeft = (blobs, board) => {
    setInitialUpload(false);
    const transformWidth = blobs.length * 100;
    const nextSiblings = getNextSiblings(board);
    const boardToAnimate = nextSiblings[blobs.length - 1];
    boardToAnimate?.classList.remove('no-animation');
    boardToAnimate?.classList.add('right');
    boardToAnimate?.style.setProperty('--r', `-${transformWidth}%`);

    setTimeout(() => {
      boardRefFirst?.current?.scrollTo(280, 0);
    }, 600);
  };

  const onUploadRight = (blobs, board) => {
    setInitialUpload(false);

    const transformWidth = blobs.length * 100;
    board?.classList.remove('no-animation');
    board?.classList.add('right');
    board?.style.setProperty('--r', `-${transformWidth}%`);

    const scrollTo = boardRefFirst.current.scrollWidth;
    setTimeout(() => {
      boardRefFirst.current.scroll(scrollTo, 0);
    }, 600);
  };

  const onUpload = (blobs, board, isLeft) => {
    if (isLeft) {
      onUploadLeft(blobs, board);
    } else {
      onUploadRight(blobs, board);
    }
  };

  const renderImages = (boards) => {
    if (!boards) return null;
    return boards.map((board, idx) => {
      const { id, blob, secureUrl, cropData } = board;
      return (
        <Board
          index={idx}
          nema={newBoards?.length}
          setBoardInMenu={setBoardInMenu}
          onRemove={onRemove}
          toggleCropper={toggleCropper}
          key={`boardWithImage-${id}`}
          board={board}
          cropData={cropData}
          blob={blob}
          secure={secureUrl}
          showBoardMenu={showBoardMenu}
        />
      );
    });
  };

  const renderBoardsDesktop = () => (
    <>
      <div
        ref={boardRefFirst}
        className="sb-BoardsStrip drag"
        style={{
          overflow: 'auto',
          alignItems: 'center',
        }}
      >
        <div
          className={`boardsWrapper ${boards ? '' : 'boardsWrapper-noBoards'}`}
        >
          {!boards?.length && (
            <div
              style={{
                gap: '48px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p
                  style={{
                    fontWeight: 900,
                    fontSize: '24px',
                    color: 'rgba(0,0,0,.8)',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                  }}
                >
                  Add photos
                </p>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: '16px',
                    color: 'rgba(0,0,0,.5)',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                  }}
                >
                  And watch the magic happen
                </p>
              </div>
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={onUploadClick}
              >
                <AddImageButtonIcon width="170px" height="170px" />
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  ref={(ref) => {
                    upload = ref;
                  }}
                  onChange={onFileChange}
                />
              </div>
            </div>
          )}
          {renderImages(boards)}
        </div>
      </div>
      {!!boards?.length && (
        <div
          onClick={onUploadClick}
          style={{
            flex: 1,
            cursor: 'pointer',
            alignSelf: 'center',
            borderRadius: '100%',
          }}
        >
          <AddImageButtonIconMobile />
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            ref={(ref) => {
              upload = ref;
            }}
            onChange={onFileChange}
          />
        </div>
      )}
    </>
  );

  const renderBoards = () => (
    <>
      <div
        ref={boardRefFirst}
        className="sb-BoardsStrip"
        style={{
          padding: '24px',
          overflow: 'auto',
        }}
      >
        <div
          style={{ overflow: 'visible' }}
          className={`boardsWrapper ${boards ? '' : 'boardsWrapper-noBoards'}`}
        >
          {!boards?.length && (
            <div
              style={{
                flex: 1,
                gap: '48px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <p
                  style={{
                    fontWeight: 800,
                    fontSize: '24px',
                    color: 'rgba(0,0,0,.8)',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                  }}
                >
                  Add photos
                </p>
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: '16px',
                    color: 'rgba(0,0,0,.5)',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                  }}
                >
                  And watch the magic happen
                </p>
              </div>
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={onUploadClick}
              >
                <AddImageButtonIcon width="170px" height="170px" />
                <input
                  hidden
                  multiple
                  type="file"
                  accept="image/*"
                  ref={(ref) => {
                    upload = ref;
                  }}
                  onChange={onFileChange}
                />
              </div>
            </div>
          )}
          {renderImages(boards)}
        </div>
      </div>
      {!!boards?.length && (
        <div
          onClick={onUploadClick}
          style={{
            flex: 1,
            cursor: 'pointer',
            alignSelf: 'center',
            borderRadius: '100%',
          }}
        >
          <AddImageButtonIconMobile />
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            ref={(ref) => {
              upload = ref;
            }}
            onChange={onFileChange}
          />
        </div>
      )}
    </>
  );

  return (
    <CSSTransition
      timeout={100}
      unmountOnExit
      in={previousFrame === frame}
      classNames={'sb-Board-animation'}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={BS_CLASS} style={{ flex: 1, justifyContent: 'center' }}>
          {/* <SpecialOfferBanner btext={btext} boards={boards} /> */}
          <div
            style={{
              gap: 8,
              flex: 1,
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              display: !!boards?.length ? 'flex' : 'none',
            }}
          >
            <span
              style={{
                fontWeight: 800,
                fontSize: '24px',
                color: 'rgba(0,0,0,.8)',
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            >
              {currentMessage}
            </span>
            <div
              style={{
                gap: 8,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Tag className="shadow" />
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 600,
                  flexBasis: '100%',
                  color: '#00000080',
                  alignItems: 'center',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                }}
              >
                {topTierDiscount
                  ? boardsUpsellMessage
                  : coupon?.discount?.percent
                    ? `${coupon?.discount?.percent}% discount awaits!`
                    : ''}
              </span>
            </div>
          </div>
          {isMobile ? renderBoards() : renderBoardsDesktop()}
        </div>
      </div>
    </CSSTransition>
  );
};

const SpecialOfferBanner = ({ btext, boards }) => {
  if (!btext) return null;
  return (
    <div
      className={`message${
        boards && boards.length > 2 ? ' message-multiple' : ''
      }`}
    >
      <img src={starStruck} alt="smiley with stars" />
      <p>
        <span className="message-bold">{btext}</span>
      </p>
    </div>
  );
};

export default BoardsStrip;
