import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { updateApp } from '../../../redux/app/actions';
import { useEffect, useRef, useState } from 'react';
import { sizeSet } from '../../../redux/app/actions';
import { u_returnFirstSize } from './util';

import { currencyFormatter } from '../upsell';
import { a_materialSizeSelected } from '../../../api/analytics';
import { calculatePriceForSize } from '../../../utils/price/calculatePrice';

export const SizeSelect = () => {
  const dispatch = useDispatch();
  const size_ = u_returnFirstSize();
  const app = useSelector((state) => state.app);
  const [selected, setSelected] = useState(size_);
  const { frames } = useSelector((state) => state.app.cms);
  const { selectedFrame } = useSelector((state) => state.boards);
  const { isCheckoutOpen } = useSelector((state) => state.checkout);
  const [frame] = frames.filter((frame) => frame.img === selectedFrame);
  const { selectedSize } = useSelector((state) => state.app);

  const { subscriptions } = app;
  const { oneTimeOrSubscription } = useSelector((state) => state.checkout);
  const { boards } = useSelector((state) => state.boards);
  const { coupon } = useSelector((state) => state.coupon);

  const updateSize = (size) => {
    dispatch(sizeSet(size));
    setSelected(size);
  };

  const handleSelectSize = (size) => {
    updateSize(size);
  };

  const getPrice = (size) => {
    const framePrice = frame?.sizes[size].price;
    const price = calculatePriceForSize(
      boards,
      coupon,
      framePrice,
      oneTimeOrSubscription,
      subscriptions,
      size,
      frame?.img,
    );
    return price;
  };

  useEffect(() => {
    const isSizeActive = frame?.sizes[+selected]?.isActive === true;
    if (!isSizeActive) {
      handleSelectSize(8);
    }
  }, [frame, selected, selectedSize]);

  const calculateDimensionsForSize = (size) => {
    switch (size) {
      case 8:
        return {
          width: '50px',
          height: '50px',
        };
        break;
      case 12:
        return {
          width: '65px',
          height: '65px',
        };
        break;
      case 16:
        {
          return {
            width: '80px',
            height: '80px',
          };
        }
        break;
    }
  };

  const refs = useRef({});
  const refsVip = useRef({});

  return (
    <div
      style={{
        zIndex: 100,
        left: '50%',
        background: 'white',
        position: 'absolute',
        transform: 'translate(-50%)',
        padding: '16px 16px 0px 16px',
        borderRadius: '20px 20px 0px 0px',
        width: isMobile ? '100%' : '450px',
        ...(isMobile ? { paddingBottom: 0 } : {}),
        boxShadow: '0px 1px 59px rgba(35, 33, 66, 0.1)',
      }}
      className={
        app.modals.size ? 'frame-select-slideUp' : 'frame-select-slideDown'
      }
    >
      <div style={{ display: 'flex', paddingRight: '16px' }}>
        <div
          style={{
            fontWeight: 700,
            fontSize: '18px',
            flexDirection: 'column',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
        >
          Select Size
        </div>
        <div
          style={{
            color: '#A638FC',
            fontSize: '16px',
            marginLeft: 'auto',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
          onClick={() => {
            dispatch(updateApp({ modals: { size: !app.modals.size } }));
          }}
        >
          Done
        </div>
      </div>
      <div
        style={{ overflow: 'hidden' }}
        className={`frame-select ${isCheckoutOpen && isMobile && 'hide'}`}
      >
        <div
          className="frames-container hide-scrollbar"
          style={{
            gap: '12px',
            overflowX: 'auto',
            padding: '20px 4px',
          }}
        >
          {[8, 12, 16].map((size) => {
            const isSizeActive = frame?.sizes[size].isActive === true;
            refs.current[size] = currencyFormatter(
              getPrice(size).totalPrice / boards?.length,
              'USD',
              2,
            );
            refsVip.current[size] = currencyFormatter(
              getPrice(size).vipBoardPrice,
              'USD',
              2,
            );
            return (
              <div
                key={size}
                style={{
                  flexBasis: '33%',
                  display: !isSizeActive ? 'none' : 'flex',
                }}
              >
                <div
                  style={{
                    gap: '8px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    padding: '16px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    alignItems: 'center',
                    borderRadius: '16px',
                    background: selected != size ? '#fff' : '#F7F0F7',
                    outline:
                      selected != size
                        ? '1px solid #EAECEE'
                        : '3px solid #C03CFC',
                    boxShadow:
                      selected == size
                        ? '5px 5px 10px rgba(132, 132, 132, 0.2)'
                        : undefined,
                  }}
                  onClick={() => {
                    handleSelectSize(size);
                    a_materialSizeSelected(size);
                  }}
                  className={`single-frame ${selected == size && 'selected'}`}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: 0,
                      fontWeight: 700,
                      fontSize: '18px',
                      textAlign: 'center',
                      fontFamily: "'Plus Jakarta Sans', sans-serif",
                      color: selected == size ? '#A637FC' : 'rgba(0,0,0,.8)',
                    }}
                  >
                    {size}”x{size}”
                  </p>
                  <div
                    style={{
                      width: '80px',
                      height: '80px',
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: '8px',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        ...calculateDimensionsForSize(size),
                        borderRadius: '2px',
                        background: selected != size ? '#F1F1F1' : '#fff',
                        outline:
                          selected != size
                            ? '3px solid transparent'
                            : '3px solid #C03CFC',
                        boxShadow:
                          '0px -0.5px 1px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 1px 0px #FFF inset, 0px 27px 8px 0px rgba(0, 0, 0, 0.00), 0px 17px 7px 0px rgba(0, 0, 0, 0.02), 0px 10px 6px 0px rgba(0, 0, 0, 0.08), 0px 4px 4px 0px rgba(0, 0, 0, 0.13), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      className={
                        oneTimeOrSubscription === 'subscription'
                          ? 'beforePrice'
                          : ''
                      }
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: 600,
                        fontSize: '12px',
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                        color: selected == size ? '#A637FC' : 'rgba(0,0,0,.5)',
                      }}
                    >
                      {refs.current[size]} each
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: 600,
                        color: '#AD37FD',
                        fontSize: '12px',
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                      }}
                    >
                      {refsVip.current[size]} VIP
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
