import React from 'react';

import './Spinner.scss';

const Spinner = ({ homeClass }) => {
  return (
    <div
      style={{
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div className="root-loader" />
    </div>
  );
};

export default Spinner;
