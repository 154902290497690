import * as TYPES from './action-types';

import { boardsActions } from '../../redux/boards';
import { clearCoupon, restorePrice } from '../coupon/actions';
import { appActions } from '../../redux/app';
import { userActions } from '../user';

import { post } from '../../utils/http';
import notify from '../../utils/notify';
import * as storage from '../../utils/storage';
import {
  a_purchase,
  a_checkoutError,
  a_orderCompleted,
} from '../../api/analytics';
import platformDetector from '../../utils/detectPlatform';

const checkoutStart = () => {
  return {
    type: TYPES.CHECKOUT_START,
  };
};

const updateOrderId = () => {
  return {
    type: TYPES.UPDATE_ORDER_ID,
  };
};

export const checkoutError = (error) => {
  return {
    type: TYPES.CHECKOUT_ERROR,
    payload: error,
  };
};

const checkoutEnd = (payload) => {
  return {
    type: TYPES.CHECKOUT_END,
    payload,
  };
};

export const klarnaLoadingStart = () => {
  return {
    type: TYPES.KLARNA_LOADING_START,
  };
};

export const klarnaLoadingStop = () => {
  return {
    type: TYPES.KLARNA_LOADING_STOP,
  };
};

export const activateKlarna = () => {
  return {
    type: TYPES.ACTIVATE_KLARNA,
  };
};

export const charge =
  ({ user, stripe, paypal, applepay, klarna }) =>
  (dispatch, getState) => {
    dispatch(checkoutStart());
    dispatch(appActions.updateApp({ footer: false }));
    dispatch(userActions.updateUserEmail(user.email));
    const { app, checkout, coupon } = getState();
    const { boards, selectedFrame } = getState().boards;

    const {
      remarketingId,
      cms,
      originalMinBoards,
      originalPriceOne,
      selectedSize,
      subscriptions,
      magicActive,
      selectedEffect,
    } = app;
    const {
      tax,
      gift,
      orderId,
      upsell,
      totalPrice,
      shippingCost,
      shippingOption,
      orderNumber,
      oneTimeOrSubscription,
      saveTextPrice,
      totalWithSubscription,
    } = checkout;

    const [activeSubscription] = subscriptions.filter(
      (subscription) => subscription.isDefault,
    );

    const [frame] = app.cms.frames.filter(
      (frame) => frame.img === selectedFrame,
    );

    // Each tile will have the same frame for now
    const tiles = boards.map((board) => ({
      ...board,
      s3Params: undefined,
      file: undefined,
      blob: undefined,
      frame: `${selectedFrame}-${selectedSize}`,
    }));
    const paymentType = paypal
      ? 'PayPal'
      : applepay
        ? 'Apple Pay'
          ? klarna
          : 'Klarna'
        : 'Credit Card';
    post('/order', null, {
      tax,
      app,
      user,
      gift,
      stripe,
      paypal,
      tiles,
      klarna,
      remarketingId,
      orderId,
      saveTextPrice,
      orderNumber,
      sku:
        +selectedSize === 12 ? 'SB02' : +selectedSize === 16 ? 'SB03' : 'SB01',
      activeSubscription,
      framePrice: frame.extraPrice,
      material: `${selectedFrame}-${selectedSize}`,
      upsell: upsell ? cms.upsell : undefined,
      coupon: coupon.coupon,
      applePay: !!applepay,
      price: totalPrice + shippingCost,
      oneTimeOrSubscription,
      shipping: { option: shippingOption, ...cms.shipping[shippingOption] },
      magicActive,
      selectedEffect,
    })
      .then((response) => {
        if (applepay) {
          applepay('success');
        }
        dispatch(appActions.updateApp({ footer: true }));
        storage.remove(storage.names.boards);
        storage.remove(storage.names.checkoutError);
        dispatch(checkoutEnd(response));
        dispatch({ type: 'CLOUDINARY_URL', payload: null });
        let order = {
          tax,
          paymentType,
          orderNumber,
          totalWithSubscription,
          material: `${selectedFrame}-${selectedSize}`,
          id: response.orderId,
          customer: user.email,
          coupon: coupon.coupon?.name || '',
          totalPrice: checkout.totalPrice,
          quantity: tiles.length,
          discountAmount: checkout.originalPrice - checkout.totalPrice,
          currency: coupon.currency || 'USD',
          shipping: {
            option: checkout.shippingOption,
            ...cms.shipping[checkout.shippingOption],
            cost: shippingCost,
          },
          oneTimeOrSubscription,
        };
        if (oneTimeOrSubscription === 'subscription') {
          localStorage.setItem('used', 1);
        }
        a_purchase(
          order,
          boards,
          oneTimeOrSubscription === 'subscription'
            ? totalWithSubscription
            : totalPrice,
        );
        a_orderCompleted(order);
        dispatch(boardsActions.invalidateBoards());
        const platform = platformDetector();
        if (platform.deviceType === 'desktop') {
          dispatch(
            restorePrice({
              minBoards: originalMinBoards,
              priceOne: originalPriceOne,
            }),
          );
        }
        dispatch(clearCoupon());
      })
      .catch((error) => {
        dispatch(checkoutError(error));
        dispatch(updateCheckout({ paymentError: error }));
        dispatch(updateCheckout({ cardPaymentOpen: true, stripe: null }));
        dispatch(updateOrderId());
        dispatch(appActions.updateApp({ footer: true }));
        if (applepay) {
          applepay('fail');
        }
        dispatch(updateId());
        notify({
          error,
          msg: `${paymentType} checkout error`,
          snapshot: getState(),
        });
        a_checkoutError(error);
        storage.remove(storage.names.checkoutError);
      });
  };

export const updateId = () => {
  return {
    type: TYPES.UPDATEID,
  };
};

export const updatePaypalInfo = (paypalUser, paypalData) => ({
  payload: { paypalUser, paypalData },
  type: TYPES.UPDATE_PAYPAL_DATA,
});

export const cancelPaypalCheckout = () => {
  return {
    type: TYPES.PAYPAL_CHECKOUT_CANCEL,
  };
};

export const simulateCheckout = () => {
  return {
    type: TYPES.CHECKOUT_SIMULATION,
  };
};

export const showCardInput = () => {
  return {
    type: TYPES.SHOW_CARD_INPUT,
  };
};

export const showPaymentMenu = () => {
  return {
    type: TYPES.SHOW_PAYMENT_MENU,
  };
};

export const activatePaypal = () => {
  return {
    type: TYPES.ACTIVATE_PAYPAL,
  };
};

export const activateCard = () => {
  return {
    type: TYPES.OPEN_CARD_FORM,
  };
};

export const paypalCheckout = () => {
  return {
    type: TYPES.PAYPAL_CHECKOUT,
  };
};
export const applePayCheckout = () => {
  return {
    type: TYPES.APPLEPAY_CHECKOUT,
  };
};
export const activateApplePay = () => {
  return {
    type: TYPES.ACTIVATE_APPLEPAY,
  };
};
export const setOriginalPrice = (payload) => {
  return {
    type: TYPES.SET_ORIGINAL_PRICE,
    payload,
  };
};

export const updateCheckout = (payload) => ({
  type: TYPES.UPDATE_CHECKOUT,
  payload,
});

export const updateStripeInfo = (stripe, cardBrand, last4) => ({
  type: TYPES.UPDATE_STRIPE_INFO,
  payload: {
    stripe,
    cardBrand,
    last4,
  },
});
