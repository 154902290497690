export const CartIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_2436_2379"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      x="0"
      y="0"
      width="20"
      height="20"
    >
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2436_2379)">
      <path
        d="M5.49562 18.0748C5.08187 18.0748 4.72916 17.9274 4.43749 17.6327C4.14583 17.3381 3.99999 16.984 3.99999 16.5702C3.99999 16.1566 4.14728 15.804 4.44187 15.5123C4.73645 15.2206 5.09062 15.0748 5.50437 15.0748C5.91812 15.0748 6.27083 15.2221 6.56249 15.5167C6.85416 15.8112 6.99999 16.1654 6.99999 16.5792C6.99999 16.9929 6.8527 17.3456 6.55812 17.6373C6.26354 17.929 5.90937 18.0748 5.49562 18.0748ZM14.4956 18.0748C14.0819 18.0748 13.7292 17.9274 13.4375 17.6327C13.1458 17.3381 13 16.984 13 16.5702C13 16.1566 13.1473 15.804 13.4419 15.5123C13.7365 15.2206 14.0906 15.0748 14.5044 15.0748C14.9181 15.0748 15.2708 15.2221 15.5625 15.5167C15.8542 15.8112 16 16.1654 16 16.5792C16 16.9929 15.8527 17.3456 15.5581 17.6373C15.2635 17.929 14.9094 18.0748 14.4956 18.0748ZM5.39541 5.57479L7.00999 9.34562H13.2608L14.8396 5.57479H5.39541ZM4.66978 3.9252H16.3556C16.6085 3.9252 16.7954 4.02652 16.9162 4.22916C17.0369 4.4318 17.0476 4.64298 16.9483 4.8627L14.7427 10.0404C14.6162 10.3301 14.4257 10.5625 14.1712 10.7375C13.9167 10.9125 13.6298 11 13.3106 11H6.68895L5.85874 12.4252H16.0748V14.0748H5.74999C5.11624 14.0748 4.65034 13.8104 4.35228 13.2817C4.05437 12.753 4.05951 12.2292 4.3677 11.7102L5.43603 9.87499L2.74687 3.57479H0.925201V1.9252H3.81562L4.66978 3.9252Z"
        fill="white"
        fill-opacity="0.7"
      />
    </g>
  </svg>
);
