import React, { useState } from 'react';
import ImageCropper from '../../components/image-cropper';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBoardInCropper,
  updateImageCropData,
} from '../../../redux/boards/actions';
import { updateApp } from '../../../redux/app/actions';
import toggleChatPopup from '../../../utils/toggleChatPopup';
import { a_adjustCrop } from '../../../api/analytics';
import { checkoutImages } from '../../../lib/images';
import './CropperMobileWrapper.scss';
import { isMobile } from '../../../utils/dimensions';
import { Dialog, DialogContent } from '@material-ui/core';

const CLASS = 'sb-CropperWrapper';

const CropperMobileWrapper = () => {
  const boards = useSelector((state) => state.boards);
  const dispatch = useDispatch();
  const isCropperActive = useSelector((state) => state.app.modals.cropper);
  const [state, setState] = useState({ currentCropData: null });
  const setCropData = (cropData) =>
    setState({ ...state, currentCropData: cropData });
  const [grid, setGrid] = useState(true);

  const renderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span
          style={{
            fontSize: '18px',
            fontWeight: 700,
            color: 'rgba(0,0,0,.8)',
            fontFamily: "'Plus Jakarta Sans', sans-serif",
          }}
        >
          Adjust cropping
        </span>
        <div style={{ cursor: 'pointer' }} onClick={closeCropper}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_2467_4250"
              maskUnits="userSpaceOnUse"
              style={{ maskType: 'alpha' }}
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_2467_4250)">
              <path
                d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                fill="#AD37FD"
              />
            </g>
          </svg>
        </div>
      </div>
    );
  };

  const closeCropper = () => {
    dispatch(updateApp({ modals: { cropper: false } }));
    setTimeout(() => dispatch(setBoardInCropper(null)), 300);
    toggleChatPopup(true, 'close cropper');
  };

  const handleDone = async () => {
    a_adjustCrop();
    closeCropper();
    dispatch(
      updateImageCropData(state.currentCropData, boards.boardInCropper.id),
    );
  };

  return (
    <>
      {isMobile ? (
        <div
          className={
            isCropperActive
              ? `${CLASS} ${CLASS}-slideUp`
              : `${CLASS} ${CLASS}-slideDown`
          }
          style={{
            padding: '24px 16px 24px 16px',
          }}
        >
          {renderHeader()}
          <div
            style={{
              flex: 1,
              gap: '24px',
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0px 24px' }}>
              <span
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  color: 'rgba(0,0,0,.5)',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                }}
              >
                Pinch, zoom or move your photo to adjust the cropping.
                <br />
                Save when you're done
              </span>
            </div>
            <ImageCropper
              grid={grid}
              style={{ top: '0px' }}
              setCropData={setCropData}
            />
            <div
              style={{
                gap: '8px',
                display: 'flex',
                borderRadius: '6px',
                padding: '4px 10px',
                alignItems: 'center',
                background: '#00000008',
                justifyContent: 'center',
                border: '1px solid #0000001A',
              }}
            >
              {grid ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-eye-off"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#AD37FD"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                  <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                  <path d="M3 3l18 18" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-eye"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#AD37FD"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                </svg>
              )}
              <span
                style={{
                  fontWeight: 600,
                  fontSize: '14px',
                  cursor: 'pointer',
                  color: 'rgba(0,0,0,.8)',
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                }}
                onClick={() => setGrid(!grid)}
              >
                {grid ? 'Hide grid' : 'Show grid'}
              </span>
            </div>
          </div>
          <button
            className={`${CLASS}-header-btnDone`}
            onClick={handleDone}
            style={{
              borderRadius: '12px',
              padding: '10px 16px 10px 16px',
              background: 'linear-gradient(90deg, #AD37FD 0%, #C03CFC 104.31%)',
              boxShadow:
                '0px -2px 2px 0px #0000001A inset, 0px 2px 2px 0px #FFFFFF73 inset',
            }}
          >
            <span
              style={{
                color: '#fff',
                fontWeight: 700,
                fontSize: '16px',
                letterSpacing: 4,
                fontFamily: "'Plus Jakarta Sans', sans-serif",
              }}
            >
              SAVE EDIT
            </span>
          </button>
        </div>
      ) : (
        <div
          className={
            isCropperActive
              ? `${CLASS} ${CLASS}-slideUp`
              : `${CLASS} ${CLASS}-slideDown`
          }
          style={{
            padding: '24px 16px 24px 16px',
          }}
        >
          {renderHeader()}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                flex: 1,
                gap: '24px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div style={{ padding: '0px 24px', textAlign: 'center' }}>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: '16px',
                    color: 'rgba(0,0,0,.5)',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                  }}
                >
                  Pinch, zoom or move your photo to adjust the cropping.
                  <br />
                  Save when you're done
                </span>
              </div>
              <ImageCropper
                grid={grid}
                style={{ top: '0px' }}
                setCropData={setCropData}
              />
              <div
                style={{
                  gap: '8px',
                  display: 'flex',
                  borderRadius: '6px',
                  padding: '4px 10px',
                  alignItems: 'center',
                  background: '#00000008',
                  justifyContent: 'center',
                  border: '1px solid #0000001A',
                }}
              >
                {grid ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-eye-off"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="#AD37FD"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                    <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                    <path d="M3 3l18 18" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-eye"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="#AD37FD"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                    <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                  </svg>
                )}
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: '14px',
                    cursor: 'pointer',
                    color: 'rgba(0,0,0,.8)',
                    fontFamily: "'Plus Jakarta Sans', sans-serif",
                  }}
                  onClick={() => setGrid(!grid)}
                >
                  {grid ? 'Hide grid' : 'Show grid'}
                </span>
              </div>
            </div>
            <button
              onClick={handleDone}
              style={{
                border: 'none',
                borderRadius: '12px',
                padding: '10px 16px 10px 16px',
                background:
                  'linear-gradient(90deg, #AD37FD 0%, #C03CFC 104.31%)',
                boxShadow:
                  '0px -2px 2px 0px #0000001A inset, 0px 2px 2px 0px #FFFFFF73 inset',
              }}
            >
              <span
                style={{
                  color: '#fff',
                  fontWeight: 700,
                  fontSize: '16px',
                  letterSpacing: 4,
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                }}
              >
                SAVE EDIT
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default CropperMobileWrapper;
