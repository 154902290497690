import * as TYPES from './action-types';
import { boardsActions } from '../boards';
import { userActions } from '../user';
import { couponActions } from '../coupon';
import { get, post } from '../../utils/http';
import notify from '../../utils/notify';
import * as events from '../../utils/events';
import { getCoupon } from '../coupon/actions';
import { nanoid } from 'nanoid';
import { updateCheckout } from '../checkout/actions';

export const setFooterVisibility = (visible) => {
  return {
    type: TYPES.SET_FOOTER_VISIBILITY,
    payload: visible,
  };
};

export const updateApp = (payload) => ({
  type: TYPES.UPDATE_APP,
  payload,
});

export const sizeSet = (size) => {
  return {
    type: TYPES.SIZE_SET,
    payload: size,
  };
};

export const setEffect = (effect) => {
  return {
    type: TYPES.SET_EFFECT,
    payload: effect,
  };
};

export const setMagic = (magic) => {
  return {
    type: TYPES.SET_MAGIC,
    payload: magic,
  };
};

export const closeModals = () =>
  updateApp({
    modals: {
      faq: false,
      addCoupon: false,
      gift: false,
      help: false,
      addVIPCoupon: false,
    },
  });

// ----- INITIALIZE -----

const initializeAppInit = () => {
  return {
    type: TYPES.INITIALIZE_APP_START,
  };
};

const initializeAppError = (error) => {
  return {
    type: TYPES.INITIALIZE_APP_ERROR,
    payload: error,
  };
};

const initializeAppEnd = () => {
  return {
    type: TYPES.INITIALIZE_APP_END,
  };
};

export const initializeApp = () => {
  return (dispatch, getState) => {
    dispatch(initializeAppInit());
    try {
      const { location } = getState().router;
      const { query } = location;
      let coupon = query.utm_term;
      let utmParams = location.search;
      if (query.session_id) {
        dispatch(userActions.loadSession(query.session_id));
        dispatch(couponActions.validateCoupon(coupon));
      } else {
        dispatch(userActions.initializeUser());
        dispatch(boardsActions.initializeBoards());
        dispatch(userActions.initializeKlaviyoUser());
        if (!coupon) {
          coupon = location.pathname.split('/').pop();
        }
        const pages = ['boards', 'privacy', 'faq', 'form', 'gift'];
        if (coupon && !pages.includes(coupon)) {
          dispatch(couponActions.validateCoupon(coupon, utmParams));
        } else {
          dispatch(couponActions.initializeCoupon());
        }
      }
      dispatch(initializeAppEnd());
    } catch (error) {
      dispatch(initializeAppError(error));
    }
  };
};

// --- GET VERSION INFO ----
const getInfoError = (payload, app) => {
  const msg = 'Failed to get admin info (price, minimum boards)';
  notify({ msg, app, error: payload });
  return {
    type: TYPES.GET_INFO_ERROR,
    payload,
  };
};

const getInfoEnd = (payload, state) => {
  const { app } = state;

  // if (payload.cms) {
  // 	payload.cms = payload.cms.find(c => c.deviceType === app.platform.deviceType);
  // }

  const coupon = getCoupon();

  payload.price.originalPriceOne = payload.price.singleBoard;
  payload.price.originalMinBoards = payload.price.minimumBoards;

  if (coupon?.metadata.boards_price) {
    payload.price.singleBoard = coupon.metadata.boards_price;
  }
  if (coupon?.metadata.min_boards) {
    payload.price.minimumBoards = coupon.metadata.min_boards;
  }

  return {
    type: TYPES.GET_INFO_END,
    payload,
  };
};

const createRemarketingId = (id) => {
  if (!id) {
    id = nanoid(12);
  }
  const cart_url = `${window.location.origin}/?session_id=${id}`;
  return { remarketingUrl: cart_url, id };
};

export const getInfo = (
  urlParams,
  id,
  oneTimeOrSubscription,
  coupon,
  override,
) => {
  let formattedSubscriptions;
  return (dispatch, getState) => {
    const appState = getState();
    const remarketingId = createRemarketingId(id);
    get(`/cms?platform=${appState.app.platform.deviceType}`)
      .then((response) => {
        if (response.id) {
          events.onInfo(response.id, appState.user.email);
        }
        if (urlParams.s_id) {
          const subscriptionExist = response.data.cms.subscriptions.filter(
            (subscription) =>
              subscription.name === urlParams.s_id &&
              subscription.isActive === true,
          );
          if (subscriptionExist.length) {
            formattedSubscriptions = response.data.cms.subscriptions.map(
              (subscription) =>
                subscription.name === urlParams.s_id &&
                subscription.isActive === true
                  ? { ...subscription, isDefault: true }
                  : { ...subscription, isDefault: false },
            );
          }
        }
        const newResponse = {
          ...remarketingId,
          cms: {
            ...response.data.cms.platform[appState.app.platform.deviceType],
            shipping: response.data.cms.shipping,
          },
          price: response.data.cms.general,
          subscriptions:
            formattedSubscriptions ||
            response.data.cms.subscriptions.filter(
              (subscription) => subscription.isActive === true,
            ),
        };
        dispatch(getInfoEnd(newResponse, appState));
        if (coupon.name.startsWith('CARDV')) {
          dispatch(
            updateCheckout({
              oneTimeOrSubscription: 'one-time',
            }),
          );
        } else {
          if (override) {
            dispatch(
              updateCheckout({
                oneTimeOrSubscription: override,
              }),
            );
            return;
          }
          dispatch(
            updateCheckout({
              oneTimeOrSubscription:
                oneTimeOrSubscription === 'subscription'
                  ? 'subscription'
                  : newResponse.subscriptions.filter(
                        (subscription) => subscription.isDefault,
                      )[0].isActiveByDefault
                    ? 'subscription'
                    : 'one-time',
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(getInfoError(error, appState));
      });
  };
};

export const saveSessionEnd = (payload) => ({
  type: TYPES.SAVE_SESSION_END,
  payload,
});

// --- MODAL TOGGLES ----

export const toggleModal = (payload) => {
  return {
    type: TYPES.TOGGLE_MODAL,
    payload,
  };
};
