export const AddImageButtonIcon = (props) => (
  <svg
    fill="none"
    width="184"
    height="196"
    viewBox="0 0 184 196"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_ddddii_2436_2400)">
      <rect
        x="7"
        y="1.5"
        width="170"
        height="170"
        rx="85"
        fill="url(#paint0_linear_2436_2400)"
      />
      <g filter="url(#filter1_i_2436_2400)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M92 51.5C89.7909 51.5 88 53.2909 88 55.5V82.5H61C58.7909 82.5 57 84.2909 57 86.5C57 88.7091 58.7909 90.5 61 90.5H88V117.5C88 119.709 89.7909 121.5 92 121.5C94.2091 121.5 96 119.709 96 117.5V90.5H123C125.209 90.5 127 88.7091 127 86.5C127 84.2909 125.209 82.5 123 82.5H96V55.5C96 53.2909 94.2091 51.5 92 51.5Z"
          fill="#AD37FD"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ddddii_2436_2400"
        x="0"
        y="0.5"
        width="184"
        height="195"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2436_2400"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2436_2400"
          result="effect2_dropShadow_2436_2400"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_2436_2400"
          result="effect3_dropShadow_2436_2400"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="17" />
        <feGaussianBlur stdDeviation="3.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_2436_2400"
          result="effect4_dropShadow_2436_2400"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect4_dropShadow_2436_2400"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect5_innerShadow_2436_2400"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_innerShadow_2436_2400"
          result="effect6_innerShadow_2436_2400"
        />
      </filter>
      <filter
        id="filter1_i_2436_2400"
        x="57"
        y="51.5"
        width="70"
        height="71"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2436_2400"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2436_2400"
        x1="92"
        y1="1.5"
        x2="92"
        y2="171.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EAEAEA" />
        <stop offset="0.855" stop-color="#F5F5F5" />
      </linearGradient>
    </defs>
  </svg>
);
