import React from 'react';
import Countdown from 'react-countdown';
import date from '../../../utils/getDate';
import { useHistory } from 'react-router-dom';
import * as actions from './actionCreators';
import * as storage from '../../../utils/storage';
import { couponActions } from '../../../redux/coupon';
import { useDispatch, useSelector } from 'react-redux';

import './OrderCompleted.scss';
import { isMobile } from 'react-device-detect';

const OrderCompleted = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { app, user, checkout } = useSelector((state) => state);

  const { shipping } = app.cms;
  const userData = storage.get(storage.names.shippingInfo);
  const name = userData && userData.fullName.split(' ');

  const handleInit = () => {
    dispatch(actions.initState());
    dispatch(couponActions.validateCoupon('NextOrder'));
    history.push('/NextOrder');
  };

  const renderer = ({ minutes, seconds }) => {
    return (
      <div style={{ gap: '4px', display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            width: '56px',
            display: 'flex',
            padding: '10px 2px',
            borderRadius: '8px',
            alignItems: 'center',
            flexDirection: 'column',
            background: '#0000000D',
            border: '1px solid #0000000D',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              fontWeight: 700,
              color: 'rgba(0,0,0,.5)',
            }}
          >
            {minutes}
          </span>
          <span
            style={{
              fontSize: '9px',
              fontWeight: 600,
              color: 'rgba(0,0,0,.5)',
            }}
          >
            Min
          </span>
        </div>
        <span style={{ color: 'rgba(0,0,0,.5)' }}>:</span>
        <div
          style={{
            width: '56px',
            display: 'flex',
            padding: '10px 2px',
            borderRadius: '8px',
            alignItems: 'center',
            flexDirection: 'column',
            background: '#0000000D',
            border: '1px solid #0000000D',
          }}
        >
          <span
            style={{
              fontSize: '18px',
              fontWeight: 700,
              color: 'rgba(0,0,0,.5)',
            }}
          >
            {seconds}
          </span>
          <span
            style={{
              fontSize: '9px',
              fontWeight: 600,
              color: 'rgba(0,0,0,.5)',
            }}
          >
            Sec
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="scope"
      style={{
        gap: '24px',
        display: 'flex',
        padding: '8px 24px',
        alignItems: 'center',
        flexDirection: 'column',
        width: !isMobile ? '640px' : undefined,
      }}
    >
      <span style={{ fontSize: '68px' }}>😍</span>
      <div
        style={{
          gap: '8px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <p
          style={{ fontSize: '24px', fontWeight: 800, color: 'rgba(0,0,0,.8)' }}
        >
          Order completed:)
        </p>
        <p
          style={{ fontSize: '16px', fontWeight: 600, color: 'rgba(0,0,0,.5)' }}
        >
          We're sending your StoryBoards for print
        </p>
      </div>
      <div
        style={{
          gap: '16px',
          width: '100%',
          display: 'flex',
          padding: '12px',
          borderRadius: '20px',
          flexDirection: 'column',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            gap: '8px',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              fontWeight: 700,
              fontSize: '20px',
              color: 'rgba(0,0,0,.8)',
            }}
          >
            Great news, {name[0]}!
          </p>
          <div>
            <p
              style={{
                fontSize: '16px',
                fontWeight: 500,
                color: 'rgba(0,0,0,.5)',
              }}
            >
              You've unlocked an exclusive treat.
            </p>
            <p
              style={{
                fontWeight: 800,
                fontSize: '16px',
                color: '#DD07C4',
              }}
            >
              Enjoy 65% off your next order!
            </p>
          </div>
        </div>
        <div
          style={{
            gap: '8px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'rgba(0,0,0,.5)',
            }}
          >
            Valid for 15 minutes
          </p>
          <Countdown date={Date.now() + 900000} renderer={renderer} />
        </div>
        <button
          onClick={handleInit}
          style={{
            border: 'none',
            cursor: 'pointer',
            borderRadius: '12px',
            padding: '10px 16px 10px 16px',
            background: 'linear-gradient(90deg, #AD37FD 0%, #C03CFC 104.31%)',
            boxShadow:
              '0px -2px 2px 0px #0000001A inset, 0px 2px 2px 0px #FFFFFF73 inset',
          }}
        >
          <span
            style={{
              color: '#fff',
              fontWeight: 700,
              fontSize: '16px',
              letterSpacing: 4,
              fontFamily: "'Plus Jakarta Sans', sans-serif",
            }}
          >
            CREATE MORE TILES
          </span>
        </button>
      </div>
      <div
        style={{
          gap: '8px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'rgba(0,0,0,.5)',
            }}
          >
            Order no.
          </p>
          <p
            style={{
              fontWeight: 600,
              fontSize: '14px',
              color: 'rgba(0,0,0,.5)',
            }}
          >
            #{checkout.orderNumber}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'rgba(0,0,0,.5)',
            }}
          >
            Confirmation sent to:
          </p>
          <p
            style={{
              fontWeight: 600,
              fontSize: '14px',
              maxWidth: '192px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: 'rgba(0,0,0,.5)',
              textOverflow: 'ellipsis',
            }}
          >
            {user.email}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p
            style={{
              fontWeight: 400,
              fontSize: '14px',
              color: 'rgba(0,0,0,.5)',
            }}
          >
            Est. delivery:
          </p>
          <p
            style={{
              fontWeight: 600,
              fontSize: '14px',
              color: 'rgba(0,0,0,.5)',
            }}
          >
            {date(Number(shipping['option1'].days))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderCompleted;
