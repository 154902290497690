import React from 'react';
import classnames from 'classnames';
import Cropper from 'react-easy-crop';
import { useSelector } from 'react-redux';
import BoardLoader from '../board-loader';
import { useElementSize } from '@mantine/hooks';

import canvas from '../../../assets/images/canvas-overlay.png';
import metallic from '../../../assets/images/metallic-overlay.png';

const overlay = {
  canvas,
  metallic,
  'canvas-foam': canvas,
  'metallic-foam': metallic,
};

const initialCropData = {
  crop: { x: 0, y: 0 },
  zoom: 1.036,
  cropData: {
    x: 0,
    y: 0,
    height: null,
    width: null,
    zoom: 1.036,
  },
};

const CLASS = 'sb-Board';

function BoardImage({ upload, board, isLoaded, image, handleImageLoaded }) {
  const { ref, width, height } = useElementSize();
  const { magicActive, selectedEffect } = useSelector((state) => state.app);

  if (upload) return null;

  const { frame, cropData } = board;
  const { crop, croppedAreaPixels } = cropData || initialCropData;
  const minZoom = 1.036;
  const zoom = cropData?.zoom ?? minZoom;

  const cropperClass = classnames('image-inside-cropper', {
    crop: !!cropData,
    [`crop-${frame}`]: frame,
    'crop-img-horizontal': board?.width > board?.height,
    'crop-img-vertical': board?.width <= board?.height,
  });

  const DEFAULT_DEPTH = {
    horizontal: 6,
    vertical: 8,
  };

  const widthWithDepth = width + DEFAULT_DEPTH.horizontal;
  const heightWithDepth = height + DEFAULT_DEPTH.vertical;

  const horizontalDepthAnglePercentage = (100 * width) / widthWithDepth;
  const verticalDepthAnglePercentage = (100 * height) / heightWithDepth;

  return (
    <div
      style={{
        overflow: 'visible',
        boxShadow: 'rgba(0, 0, 0, 0.05) -5px -5px 5px',
      }}
      className={`${CLASS}-container ${CLASS}-container-${frame}`}
    >
      {(frame === 'metallic' ||
        frame === 'canvas' ||
        frame === 'canvas-foam' ||
        frame === 'metallic-foam') && (
        <img
          alt="cover"
          src={overlay[frame]}
          style={{
            width: '255px',
            zIndex: 333333,
            height: '255px',
            objectFit: 'cover',
            position: 'relative',
            opacity: frame === 'canvas-foam' ? 0.5 : undefined,
            mixBlendMode:
              frame === 'canvas-foam' ? 'soft-light' : 'plus-lighter',
          }}
        />
      )}
      <div
        ref={ref}
        className="tile-frame"
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
        }}
      >
        <div
          style={{
            top: 0,
            left: width,
            position: 'absolute',
            height: heightWithDepth,
            background: 'rgb(77, 77, 77)',
            width: DEFAULT_DEPTH.horizontal,
            clipPath: `polygon(0 0, 100% ${
              100 - verticalDepthAnglePercentage
            }%, 100% 100%, 0 ${verticalDepthAnglePercentage}%)`,
          }}
        />

        <div
          style={{
            left: 0,
            top: height,
            position: 'absolute',
            width: widthWithDepth,
            background: 'rgb(38, 38, 38)',
            height: DEFAULT_DEPTH.vertical,
            clipPath: `polygon(0 0, ${horizontalDepthAnglePercentage}% 0, 100% 100%, ${
              100 - horizontalDepthAnglePercentage
            }% 100%)`,
          }}
        />

        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            boxShadow:
              '-2px 1px 5px 0px #00000033,-8px 3px 9px 0px #00000033,-19px 7px 12px 0px #00000024,-52px 19px 16px 0px #00000003',
          }}
        />
      </div>
      <div
        style={{
          overflow: 'hidden',
          transition: 'filter 0.3s ease-in-out',
        }}
        className={`${CLASS}-content ${CLASS}-content-${frame} ${magicActive ? 'Magic' : selectedEffect}`}
      >
        {isLoaded ? (
          <Cropper
            className={`${CLASS}-cropper`}
            classes={{
              containerClassName: `container-cropper`,
              imageClassName: cropperClass,
              cropAreaClassName: 'crop-area',
            }}
            image={board.secureUrl || board.blob}
            showGrid={false}
            crop={crop}
            zoom={zoom}
            aspect={1}
            minZoom={minZoom}
            maxZoom={1.5}
            restrictPosition={true}
            initialCroppedAreaPixels={croppedAreaPixels}
            zoomSpeed={0.03}
            cropSize={{ width: 255, height: 255 }}
            onCropChange={() => {}}
          />
        ) : (
          <BoardLoader />
        )}
        <img
          hidden
          alt="loading"
          onLoad={handleImageLoaded}
          src={board.secureUrl || board.blob}
        />
      </div>
    </div>
  );
}

export default BoardImage;
