import classnames from 'classnames';
import Cropper from 'react-easy-crop';
import { Slider } from '@mantine/core';
import BoardLoader from '../board-loader';
import { useSelector } from 'react-redux';
import { useElementSize } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';

import './ImageCropper.scss';
import frameBold from '../../../assets/images/boards/frames/Bold-1.png';
import frameEver from '../../../assets/images/boards/frames/Ever-1.png';
import frameClassic from '../../../assets/images/boards/frames/Default.png';

const CLASS = 'sb-ImageCropper';
const initialZoom = 1.036;

const initialState = {
  crop: { x: 0, y: 0 },
  zoom: initialZoom,
  cropData: {
    x: 0,
    y: 0,
    height: null,
    width: null,
    zoom: initialZoom,
  },
};

const ImageCropper = (props) => {
  const [image, setImage] = useState(null);
  const [state, setState] = useState(initialState);
  const selectedFrame = useSelector((state) => state.boards.selectedFrame);
  const boardInCropper = useSelector((state) => state.boards.boardInCropper);

  const { ref, width, height } = useElementSize();
  const DEFAULT_DEPTH = {
    horizontal: 6,
    vertical: 8,
  };

  const widthWithDepth = width + DEFAULT_DEPTH.horizontal;
  const heightWithDepth = height + DEFAULT_DEPTH.vertical;

  const horizontalDepthAnglePercentage = (100 * width) / widthWithDepth;
  const verticalDepthAnglePercentage = (100 * height) / heightWithDepth;

  useEffect(() => {
    setImage(null);
    boardInCropper && setImage(boardInCropper.secureUrl || boardInCropper.blob);
  }, [boardInCropper]);

  useEffect(() => {
    if (boardInCropper && !!boardInCropper.cropData) {
      const { cropData } = boardInCropper;
      setState({
        crop: cropData.crop,
        zoom: cropData.zoom,
        cropData: { ...cropData },
      });
    } else setState(initialState);
  }, [boardInCropper]);

  const onCropChange = (crop) => {
    setState({
      ...state,
      crop,
      cropData: {
        ...state.cropData,
        crop,
      },
    });
  };

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    props.setCropData({
      zoom: state.cropData.zoom,
      crop: state.crop,
      width: croppedAreaPixels?.width,
      height: croppedAreaPixels?.height,
      x: croppedAreaPixels.x,
      y: croppedAreaPixels.y,
      croppedAreaPixels,
    });
  };

  const onZoomChange = (zoom) => {
    setState({
      ...state,
      zoom,
      cropData: {
        ...state.cropData,
        zoom,
      },
    });
  };

  const renderFrame = () => {
    return (
      <div
        className={`${CLASS}-frame ${selectedFrame === 'classic' && 'isClassic'}`}
      >
        <img
          src={frameClassic}
          className={`${selectedFrame === 'classic' && 'show'}`}
          alt="Frame"
        />
        <img
          src={frameBold}
          className={`${['bold', 'clean'].includes(selectedFrame) && 'show'}`}
          alt="Frame"
        />
        <img
          src={frameEver}
          className={`${['ever', 'edge'].includes(selectedFrame) && 'show'}`}
          alt="Frame"
        />
      </div>
    );
  };

  const minZoom = boardInCropper?.frame === 'classic' ? 1.036 : 1.0;

  const cropperClass = classnames('image-inside-cropper', {
    crop: !!boardInCropper?.cropData,
    [`crop-${boardInCropper?.frame}`]: boardInCropper?.frame,
    'crop-img-horizontal': boardInCropper?.width > boardInCropper?.height,
    'crop-img-vertical': boardInCropper?.width <= boardInCropper?.height,
  });

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <div className={CLASS} style={{ ...props.style }}>
        <div
          ref={ref}
          className={`${CLASS}-container ${CLASS}-container-${boardInCropper?.frame || ''}`}
        >
          <div
            style={{
              top: 0,
              left: width,
              position: 'absolute',
              height: heightWithDepth,
              background: 'rgb(77, 77, 77)',
              width: DEFAULT_DEPTH.horizontal,
              clipPath: `polygon(0 0, 100% ${
                100 - verticalDepthAnglePercentage
              }%, 100% 100%, 0 ${verticalDepthAnglePercentage}%)`,
            }}
          />

          <div
            style={{
              left: 0,
              top: height,
              position: 'absolute',
              width: widthWithDepth - 1,
              background: 'rgb(38, 38, 38)',
              height: DEFAULT_DEPTH.vertical,
              clipPath: `polygon(0 0, ${horizontalDepthAnglePercentage}% 0, 100% 100%, ${
                100 - horizontalDepthAnglePercentage
              }% 100%)`,
            }}
          />

          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              boxShadow:
                'rgba(0, 0, 0, 0.07) 14.06px 14.06px 12.8457px, rgba(0, 0, 0, 0.12) 7.68px 7.68px 7.74008px, rgba(0, 0, 0, 0.16) 3.39px 3.39px 4.31018px, rgba(0, 0, 0, 0.05) 0.32px 0.32px 1.85378px',
            }}
          />
          {!image ? (
            <BoardLoader />
          ) : (
            <>
              {renderFrame()}
              <Cropper
                className={`${CLASS}-cropper`}
                classes={{
                  imageClassName: cropperClass,
                  cropAreaClassName: 'crop-area',
                  containerClassName: `container-cropper`,
                }}
                aspect={1}
                maxZoom={1.5}
                image={image}
                zoomSpeed={0.03}
                minZoom={minZoom}
                crop={state.crop}
                zoom={state.zoom}
                showGrid={props.grid}
                restrictPosition={true}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={onCropComplete}
                cropSize={{ width: 255, height: 255 }}
                initialCroppedAreaPixels={state.croppedAreaPixels}
              />
            </>
          )}
        </div>
      </div>
      <div
        style={{
          gap: 8,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg
          width={24}
          height={24}
          fill="none"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke="rgba(0,0,0,.5)"
          stroke-linejoin="round"
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-photo-minus"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M15 8h.01M12.5 21H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v9" />
          <path d="m3 16 5-5c.928-.893 2.072-.893 3 0l4 4" />
          <path d="m14 14 1-1c.928-.893 2.072-.893 3 0l2 2M16 19h6" />
        </svg>
        <div style={{ flex: 1 }}>
          <Slider
            max={1.5}
            label={null}
            step={0.001}
            min={minZoom}
            color="grape"
            value={state.zoom}
            onChange={(value) => onZoomChange(value)}
          />
        </div>
        <svg
          width={24}
          height={24}
          fill="none"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke="rgba(0,0,0,.5)"
          stroke-linejoin="round"
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-photo-plus"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M15 8h.01M12.5 21H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v6.5" />
          <path d="m3 16 5-5c.928-.893 2.072-.893 3 0l4 4" />
          <path d="m14 14 1-1c.67-.644 1.45-.824 2.182-.54M16 19h6M19 16v6" />
        </svg>
      </div>
    </div>
  );
};

export default ImageCropper;
