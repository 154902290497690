export const EffectIcon = (props) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_ii_2467_4576)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.5 15C30.5 18.4847 27.1291 20.625 23.6444 20.625H19.2196C17.6972 20.625 16.684 22.1985 17.314 23.5845L18.0683 25.244C19.0549 27.4144 17.8841 30 15.5 30C7.21574 30 0.5 23.2843 0.5 15C0.5 6.71572 7.21574 0 15.5 0C23.7843 0 30.5 6.71572 30.5 15ZM15.5 16.875C16.5355 16.875 17.375 16.0355 17.375 15C17.375 13.9645 16.5355 13.125 15.5 13.125C14.4645 13.125 13.625 13.9645 13.625 15C13.625 16.0355 14.4645 16.875 15.5 16.875Z"
        fill="#F1F1F1"
      />
    </g>
    <g filter="url(#filter1_ii_2467_4576)">
      <circle cx="24.5" cy="14" r="3" fill="#A2E6ED" />
    </g>
    <circle
      cx="24.5"
      cy="14"
      r="2.75"
      stroke="white"
      stroke-opacity="0.4"
      stroke-width="0.5"
    />
    <g filter="url(#filter2_ii_2467_4576)">
      <circle cx="6.5" cy="14" r="3" fill="#BFE989" />
    </g>
    <circle
      cx="6.5"
      cy="14"
      r="2.75"
      stroke="white"
      stroke-opacity="0.4"
      stroke-width="0.5"
    />
    <g filter="url(#filter3_ii_2467_4576)">
      <circle cx="20" cy="7" r="3" fill="#FBAFFB" />
    </g>
    <circle
      cx="20"
      cy="7"
      r="2.75"
      stroke="white"
      stroke-opacity="0.4"
      stroke-width="0.5"
    />
    <g filter="url(#filter4_ii_2467_4576)">
      <circle cx="11" cy="7" r="3" fill="#F4CE6E" />
    </g>
    <circle
      cx="11"
      cy="7"
      r="2.75"
      stroke="white"
      stroke-opacity="0.4"
      stroke-width="0.5"
    />
    <defs>
      <filter
        id="filter0_ii_2467_4576"
        x="0.5"
        y="-0.5"
        width="30"
        height="31.5"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2467_4576"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_2467_4576"
          result="effect2_innerShadow_2467_4576"
        />
      </filter>
      <filter
        id="filter1_ii_2467_4576"
        x="21.5"
        y="11"
        width="6"
        height="8"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2467_4576"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.3" />
        <feGaussianBlur stdDeviation="0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_2467_4576"
          result="effect2_innerShadow_2467_4576"
        />
      </filter>
      <filter
        id="filter2_ii_2467_4576"
        x="3.5"
        y="11"
        width="6"
        height="8"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2467_4576"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.5" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_2467_4576"
          result="effect2_innerShadow_2467_4576"
        />
      </filter>
      <filter
        id="filter3_ii_2467_4576"
        x="17"
        y="4"
        width="6"
        height="8"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2467_4576"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.5" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_2467_4576"
          result="effect2_innerShadow_2467_4576"
        />
      </filter>
      <filter
        id="filter4_ii_2467_4576"
        x="8"
        y="4"
        width="6"
        height="8"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2467_4576"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.5" />
        <feGaussianBlur stdDeviation="0.25" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_2467_4576"
          result="effect2_innerShadow_2467_4576"
        />
      </filter>
    </defs>
  </svg>
);
