export const Tag = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.9 12.75L12.75 19.9C12.55 20.1 12.325 20.25 12.075 20.35C11.825 20.45 11.575 20.5 11.325 20.5C11.075 20.5 10.825 20.45 10.575 20.35C10.325 20.25 10.1 20.1 9.9 19.9L1.075 11.075C0.891667 10.8917 0.75 10.6792 0.65 10.4375C0.55 10.1958 0.5 9.94167 0.5 9.675V2.5C0.5 1.95 0.695833 1.47917 1.0875 1.0875C1.47917 0.695833 1.95 0.5 2.5 0.5H9.675C9.94167 0.5 10.2 0.554167 10.45 0.6625C10.7 0.770833 10.9167 0.916667 11.1 1.1L19.9 9.925C20.1 10.125 20.2458 10.35 20.3375 10.6C20.4292 10.85 20.475 11.1 20.475 11.35C20.475 11.6 20.4292 11.8458 20.3375 12.0875C20.2458 12.3292 20.1 12.55 19.9 12.75ZM11.325 18.5L18.475 11.35L9.65 2.5H2.5V9.65L11.325 18.5ZM5 6.5C5.41667 6.5 5.77083 6.35417 6.0625 6.0625C6.35417 5.77083 6.5 5.41667 6.5 5C6.5 4.58333 6.35417 4.22917 6.0625 3.9375C5.77083 3.64583 5.41667 3.5 5 3.5C4.58333 3.5 4.22917 3.64583 3.9375 3.9375C3.64583 4.22917 3.5 4.58333 3.5 5C3.5 5.41667 3.64583 5.77083 3.9375 6.0625C4.22917 6.35417 4.58333 6.5 5 6.5Z"
      fill="#AD37FD"
    />
  </svg>
);
